import './AppRouter.css';
import { useContext,useEffect,useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuth/*, hasAuthParams*/ } from "react-oidc-context";
import HeaderComponent from './components/wrapper/header/header';
import FooterComponent from './components/wrapper/footer/footer';
import LoginComponent from './components/account/login';
import RegisterComponent from './components/account/register';
import ForgotPassword from './components/account/password';
import ForgotPasswordReset from './components/account/passwordreset';
import MandatoryForgotPasswordReset from './components/account/mandatorypasswordreset';
import PasswordUpdated from './components/account/updated';
import PrimoAccesso from './components/account/primoaccesso/primoaccesso';
import Dashboard from './components/dashboard/dashboard';
import Profile from './components/account/profile/profile';
import HelpCenter from './components/helpdesk/HelpCenter';
import Preferences from './components/account/profile/preferences';
import ChangePassword from './components/account/profile/changePassword';
import DeleteUser from './components/account/profile/deleteUser';
import AppContext from './AppContext';
import UserContext from './UserContext';
import CommonContext from './CommonContext';
import {uscall,getfirst} from './us';
import Checkout from  './components/checkout/Checkout';
import CreateCheckout from  './components/checkout/CreateCheckout';
import MyProducts from './components/myproducts/myproducts';
import Ricevuta from './components/myproducts/ricevuta';
import LandingEdu from './components/landing/edu';
import Landing from './components/landing/landing';
import CodeUnlock from './components/codeunlock/codeunlock';

function InnerAppRouter() {
	const auth = useAuth();
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [globalusercontext,setGlobalUserContext]=useState(null);
	const [globalcommoncontext,setGlobalCommonContext]=useState(null);
	const location = useLocation();
        const appcontext = useContext(AppContext);
	const navigate=useNavigate();

	useEffect(() => {
		if (auth && auth.isAuthenticated) {
			setIsAuthenticated(true);
		}
	}, [auth]);
	const reloadusercontext=async function(){
		const tokeninfo=(await uscall(appcontext,auth,'aaa','session','tokeninfo',null,null));
		if(tokeninfo);else return;
		const user=getfirst(await uscall(appcontext,auth,'aaa','user','load',null,[tokeninfo.user]));
		//if(user.professione);else if(location.pathname!=='/profilo/primoaccesso/') return navigate('/profilo/primoaccesso/');
		user.professionecode=getfirst(await uscall(appcontext,auth,'pim','professione','load',[user.professione]));
		setGlobalUserContext({
			tokeninfo:tokeninfo,
			user:user
		});
	};

	useEffect(() => {
		if (auth && auth.isAuthenticated && (!auth.isLoading) && (!auth.error) && auth.user && auth.user.access_token) {
			if(!globalusercontext){
				if(appcontext){
					reloadusercontext();
				}
			}
		}else{
			if(globalusercontext)
				setGlobalUserContext(null);
		}
	}, [auth,appcontext,globalusercontext/*,reloadusercontext*/]);
	/*useEffect(() => {
		if(globalusercontext && globalusercontext.user){
			if(globalusercontext.user.professione);else if(location.pathname!=='/profilo/primoaccesso/') return navigate('/profilo/primoaccesso/');
		}
	}, [globalusercontext,location.pathname,navigate]);*/
	useEffect(() => {
		const cb=async function(){
			const r=await uscall(appcontext,auth,'ecommerce',null,'producttype_search',null,null);
			const pt={};
			if(typeof(r['error'])!='undefined');
			else if(typeof(r['result'])!='undefined' && r.result){
				for(let i=0;i<r.result.length;i++)pt[r.result[i].uuid]=r.result[i];
			}
			setGlobalCommonContext({
				producttypes:pt,
			});
		};
		if(globalcommoncontext);else cb();
	}, [auth,appcontext,globalcommoncontext]);

	/*useEffect(() => {
		const checkPrimoAccesso=async function(){
			const user=globalusercontext.user;
			if(user.professione);else return navigate('/profilo/primoaccesso/');
			user.professionecode=getfirst(await uscall(appcontext,auth,'pim','professione','load',null,[user.professione])).code;
		};
		if(location.pathname==='/dashboard/'){
			if(globalusercontext && globalusercontext.user)
				checkPrimoAccesso();
		}
	}, [appcontext,auth,globalusercontext,navigate,location.pathname]);*/

	return (
    		<CommonContext.Provider value={globalcommoncontext}> 
    		<UserContext.Provider value={globalusercontext}>
			<div className={`App ${isAuthenticated && (location.pathname === '/' || location.pathname === '/dashboard/') ? 'authenticated' : ''}${location.pathname.startsWith('/landingpage/') ? 'landing' : ''}`}>
				<>{location.pathname.substr(0,'/ricevuta/'.length)==='/ricevuta/'?(<></>):(
					<HeaderComponent reloadusercontext={reloadusercontext} />
				)}</>
				<main className={`${location.pathname === '/landingpage/' ? 'main-landing' : ''}`}>
					{
						auth && auth.activeNavigator === 'signinSilent' ? (
							<div>Signing you in...</div>
						) : (
							auth && auth.activeNavigator === 'signoutRedirect' ? (
								<div>Signing you out...</div>
							) : (
								auth && auth.isLoading ? (
									<div>Loading...</div>
								) : (
									auth && auth.error ? (
										<div>Oops... {auth.error.message}</div>
									) : (
										<Routes>
											<Route path="/profilo/primoaccesso/" element={<PrimoAccesso reloadusercontext={reloadusercontext} />} />
											<Route path="/login/" element={<LoginComponent />} />
											<Route path="/register/" element={<RegisterComponent />} />
											<Route path="/forgot-password/" element={<ForgotPassword />} />
											<Route path="/forgot-password/reset/:token" element={<ForgotPasswordReset />} />
											<Route path="/mandatory-password/reset/:token" element={<MandatoryForgotPasswordReset />} />
											<Route path="/forgot-password/password-updated" element={<PasswordUpdated />} />
											<Route path='/dashboard/' element={<Dashboard />} />
											<Route path="/profile/" element={<Profile reloadusercontext={reloadusercontext} />} />
											<Route path="/help-center/" element={<HelpCenter />} />
											<Route path="/profile/preferences/" element={<Preferences reloadusercontext={reloadusercontext} />} />
											<Route path="/profile/change-password/" element={<ChangePassword reloadusercontext={reloadusercontext} />} />
											<Route path="/profile/delete-user/" element={<DeleteUser reloadusercontext={reloadusercontext} />} />
											<Route path="/checkout/:order" element={<Checkout reloadusercontext={reloadusercontext} />} />
											<Route path="/create-checkout/:productcode" element={<CreateCheckout />} />
											<Route path="/" element={<Navigate to="/dashboard/" replace />} />
											<Route path="/my-products/" element={<MyProducts />} />
											<Route path="/ricevuta/:ordercode/:paymentcode" element={<Ricevuta />} />
											<Route path="/ricevuta/:ordercode" element={<Ricevuta />} />
											<Route path="/edu/" element={<LandingEdu />} />
											<Route path="/landingpage/landing" element={<Landing landingPage='landing' />} />
											<Route path="/landingpage/edu" element={<Landing landingPage='edu' />} />
											<Route path="/landingpage/universita" element={<Landing landingPage='universita' />} />
											<Route path="/landingpage/studenti" element={<Landing landingPage='studenti' />} />
											<Route path="/landingpage/docenti" element={<Landing landingPage='docenti' />} />
											<Route path="/landingpage/professionali" element={<Landing landingPage='professionali' />} />
											<Route path="/landingpage/enti-biblioteche" element={<Landing landingPage='enti-biblioteche' />} />
											<Route path="/landingpage/biblioteca-digitale" element={<Landing landingPage='biblioteca-digitale' />} />
											<Route path="/attivaprodotto" element={<CodeUnlock contentpage={auth && auth.isAuthenticated?"auth":"unlock"} />} />
											<Route path="/attivaomaggio" element={<CodeUnlock contentpage={auth && auth.isAuthenticated?"auth":"gifted"} />} />
											<Route path="/attivaebook" element={<CodeUnlock contentpage={auth && auth.isAuthenticated?"auth":"ebook"} />} />
											<Route path="/attivaebookedu" element={<CodeUnlock contentpage={auth && auth.isAuthenticated?"auth":"edu"} />} />
											<Route path="/attivadaecomm" element={<CodeUnlock contentpage={auth && auth.isAuthenticated?"auth":"attivadaecomm"} />} />

										</Routes>
									)
								)
							)
						)
					}
				</main>
				{/* footer sempre visibile, in tutte le pagine, come da richiesta di inizio novembre 2024 */}
				<FooterComponent />

				{/*
				<>{
				location.pathname.substr(0,'/login'.length)==='/login' || 
				location.pathname.substr(0,'/register'.length)==='/register' || 
				location.pathname.substr(0,'/forgot-password'.length)==='/forgot-password' || 
				location.pathname.substr(0,'/ricevuta/'.length)==='/ricevuta/' || 
				  location.pathname==='/forgot-password/password-updated/' || 
				location.pathname.substr(0, '/profilo/primoaccesso'.length) === '/profilo/primoaccesso' || 
				location.pathname.substr(0, '/attivaprodotto'.length) === '/attivaprodotto' || 
				location.pathname.substr(0, '/attivaomaggio'.length) === '/attivaomaggio' || 
				location.pathname.substr(0, '/attivaebook'.length) === '/attivaebook' || 
				location.pathname.substr(0, '/attivaebookedu'.length) === '/attivaebookedu' || 
				location.pathname.substr(0, '/attivadaecomm'.length) === '/attivadaecomm' || 
				location.pathname.substr(0, '/codeunlock'.length) === '/codeunlock' ? 
				(<></>) : (
				<FooterComponent />
				)}</>
				*/}

			</div>
    		</UserContext.Provider>
    		</CommonContext.Provider>
	);
}
function AppRouter(){
	return (
		<BrowserRouter>
			<InnerAppRouter />
		</BrowserRouter>
	);
}


export default AppRouter;
