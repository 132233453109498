import React from 'react';
import { useState,useEffect,useContext, } from 'react';
import Modal from 'react-bootstrap/Modal';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useAuth } from 'react-oidc-context';
import AppContext from '../../AppContext';
//import UserContext from '../../UserContext';
import {uscall} from '../../us';
import { useNavigate,useLocation } from 'react-router-dom';

const CustomModal = ({ modalType, showModal, handleClose, handleSubmit, title, bodyContent, bodySubtitle, closeButtonText, icon, closeButtonAction,reloadusercontext }) => {
    const auth = useAuth();
    const appcontext = useContext(AppContext);
    //const usercontext = useContext(UserContext);

    const [isCodeVisible, setIsCodeVisible] = useState(false);

    const toggleCodeVisibility = () => {
        setIsCodeVisible(!isCodeVisible);
    };
	const [curCode,setCurCode]=useState('');
	const [CodeClassName,setCodeClassName]=useState('');
	const [canBeSubmitted,setCanBeSubmitted]=useState(false);
    let navigate = useNavigate();
	const location = useLocation();
	useEffect(()=>{
		const cb=async function(){
			if(curCode && (''+curCode).trim().length>0){
				let r=await uscall(appcontext,auth,'order','order','order_verify_codiceattivazione',encodeURIComponent((''+curCode).trim()),null);
				if(r && r.result){
					setCodeClassName('codeValid');
					setCanBeSubmitted(true);
				}else{
					setCodeClassName('codeInvalid');
					setCanBeSubmitted(false);
				}
			}else{
				setCodeClassName('');
				setCanBeSubmitted(false);
			}
		};
		if(auth && auth.isAuthenticated && (!auth.isLoading) && (!auth.error) && auth.user && auth.user.access_token)
			cb();
	},[curCode,auth,appcontext]);

	const UnlockResource=async function(e){
		e.preventDefault();
		e.stopPropagation();
		if(canBeSubmitted);else return false;
		let r=await uscall(appcontext,auth,'order','order','order_create_with_codiceattivazione',encodeURIComponent((''+curCode).trim()),null);
		if(r && r.result){
			reloadusercontext();
			if(location.pathname === '/dashboard/')navigate(0);
			else navigate('/dashboard/');
		}

		return false;
	};

    const bodyContentLines = bodyContent.split('<br/>');

    const renderModalContent = () => {
        switch (modalType) {
            case 'resetPasswordInstructions':
                return (
                    <Modal className={`${location.pathname === '/dashboard/' ? 'black-modal' : ''}`} show={showModal} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title><h2>{title}</h2></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {bodyContentLines.map((line, index) => (
                                <p key={index}>{line}</p>
                            ))}
                            {bodySubtitle && <h4>{bodySubtitle}</h4>}
                        </Modal.Body>
                        <Modal.Footer>
                            <button className='gradient-button reset-button' onClick={handleClose}>Annulla</button>
                            <button className='gradient-button' onClick={handleSubmit}>Invia</button>
                        </Modal.Footer>
                    </Modal>
                );
            case 'passwordResetConfirmation':
                return (
                    <Modal className={`${location.pathname === '/dashboard/' ? 'black-modal' : ''}`} show={showModal} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title><h2>{title}</h2></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {bodyContentLines.map((line, index) => (
                                <p key={index}>{line}</p>
                            ))}
                            {bodySubtitle && <h4>{bodySubtitle}</h4>}
                        </Modal.Body>
                        <Modal.Footer>
                            <button className='gradient-button modal-close-button' onClick={handleClose}>{closeButtonText}</button>
                        </Modal.Footer>
                    </Modal>
                );
            case 'deleteUser':
                return (
                    <Modal className={`${location.pathname === '/dashboard/' ? 'black-modal' : ''}`} show={showModal} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {icon && <img src={icon} alt="Cancellazione utente" style={{ marginRight: '8px' }} />}
                                <h2>{title}</h2>
                                </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {bodySubtitle && <h4 className='mb-2'>{bodySubtitle}</h4>}
                            {bodyContentLines.map((line, index) => (
                                <p key={index}>{line}</p>
                            ))}
                        </Modal.Body>
                        <Modal.Footer>
                            <button className='gradient-button modal-close-button' onClick={handleClose}>{closeButtonText}</button>
                        </Modal.Footer>
                    </Modal>
                );
            case 'userLogout':
                return (
                    <Modal className={`${location.pathname === '/dashboard/' ? 'black-modal' : ''}`} show={showModal} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title><h2>{title}</h2></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {bodySubtitle && <h4 className='mb-2'>{bodySubtitle}</h4>}
                            {bodyContentLines.map((line, index) => (
                                <p key={index}>{line}</p>
                            ))}
                        </Modal.Body>
                        <Modal.Footer>
                            <button className='gradient-button reset-button' onClick={handleClose}>Annulla</button>
                            <button className='gradient-button' onClick={handleSubmit}>Conferma</button>
                        </Modal.Footer>
                    </Modal>
                );
            case 'activateProduct':
                return (
                    <Modal className={`${location.pathname === '/dashboard/' ? 'black-modal' : ''}`} show={showModal} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {icon && <img src={icon} alt="Cancellazione utente" style={{ marginRight: '8px' }} />}
                                <h2>{title}</h2>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {bodySubtitle && <h4 className='mb-2'>{bodySubtitle}</h4>}
                            {bodyContentLines.map((line, index) => (
                                <p key={index}>{line}</p>
                            ))}
                            <div className='find-code-container'>
                                <p onClick={toggleCodeVisibility} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                    <span>Dove trovo il codice?</span>
                                    {isCodeVisible ? <FaChevronUp style={{ marginLeft: '8px' }} /> : <FaChevronDown style={{ marginLeft: '8px' }} />}
                                </p>
                                {isCodeVisible && (
                                    <div className='find-code'></div>
                                )}
                            </div>
                            <div className='form-group'>
                                <label htmlFor='activationCode'>Attiva prodotto</label>
                                <input placeholder='Inserisci codice' type='text' className={CodeClassName} value={curCode} onChange={(e)=>{setCurCode(e.target.value)}} />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className='gradient-button reset-button' onClick={handleClose}>Annulla</button>
                            <button className='gradient-button' onClick={UnlockResource}>Conferma</button>
                        </Modal.Footer>
                    </Modal>
                );
            default:
                return null;
        }
    };

    return renderModalContent();
};

export default CustomModal;
