import "./profile.css";
import { useEffect, useState, useContext } from 'react';
import { useNavigate/*, Link*/ } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import AppContext from '../../../AppContext';
import { uscall } from '../../../us';
import { notifyWarn,notifySuccess } from '../../utilities/toaster';
import { ToastContainer } from 'react-toastify';
import UserContext from '../../../UserContext';
import PrevPage from "../../utilities/prevPage";

const Profile = ({reloadusercontext}) => {
    const auth = useAuth();
    const appcontext = useContext(AppContext);
    const usercontext = useContext(UserContext);
    let navigate = useNavigate();
    useEffect(() => {
      if(auth.isAuthenticated);
      else 
        navigate('/login/?redirect='+encodeURIComponent(document.location.href));
    },[auth,navigate]);

    const [userData,setUserData]=useState(null);
    const [curuserData,setCurUserData]=useState(null);
    const [professioneStudente,setProfessioneStudente]=useState(null);
    const [professioneProfessore,setProfessioneProfessore]=useState(null);
    const [professioni,setProfessioni]=useState(null);
    const [interessi,setInteressi]=useState(null);
    const [materie,setMaterie]=useState(null);
    const [universita,setUniversita]=useState(null);
    const [corsilaurea,setCorsiLaurea]=useState(null);
    const [changes,setChanges]=useState({});
    //const [isSubmitted, setIsSubmitted] = useState(false);// used to raise warnings
    const [isSubmitting, setIsSubmitting] = useState(false);// used to avoid double submissions

    //const effectRan = useRef(false);// use to avoid in dev mode double rendering after redirect, causing NS_BINDING_ERR: 

    const loadCorsiLaurea=async function(universita){
	    if(universita){
		    setCorsiLaurea((await uscall(appcontext,auth,'pim',null,'getall-corsolaurea',null,{filter:{field:'universita',func:'eq',value:universita},sort:{field:'name',desc:false}})).result);
	    } else {
            setCorsiLaurea([]);
        }
    }

	const updateField=async function(field,value){
		if(curuserData && professioneStudente && professioneProfessore);else return;
		if(field==='professione'){
			if(curuserData && curuserData.professione)return;
		}
	    	if(curuserData.professione===professioneStudente.uuid){
                if(field==='universita'){
                    loadCorsiLaurea(value);
                }
		}

		let nc={...changes};nc[field]=value;
		setChanges(nc);
		let nud={...curuserData};nud[field]=value;
		setCurUserData(nud);
	}

	const loadUserData=async function(){
		//const tokeninfo=(await uscall(appcontext,auth,'aaa','session','tokeninfo',null,null));
		//if(tokeninfo);else return;
		//const user=getfirst(await uscall(appcontext,auth,'aaa','user','load',null,[tokeninfo.user]));
		const user=usercontext.user;
		setUserData(user);
		setCurUserData({...user});
	}

    useEffect(() => {
	if((!userData) && usercontext && usercontext.user){
		//if(!effectRan.current){
			loadUserData();
		//	return () => effectRan.current = true;
		//}
	}
    }, [userData,auth,appcontext,usercontext/*,loadUserData*/]);

    useEffect(() => {
	    const cb=async function(){setProfessioni((await uscall(appcontext,auth,'pim',null,'getall-professione',null,{sort:{field:'position',desc:false}})).result);};
	    if(!professioni)cb();
    }, [professioni,appcontext,auth]);

    useEffect(() => {
	    if(professioni && (!professioneStudente)){
		    for(let i=0;i<professioni.length;i++){
			    if(professioni[i].code==='student'){
				    setProfessioneStudente(professioni[i]);
				    break;
			}
		}
	}
    }, [professioni,professioneStudente]);
    
    useEffect(() => {
	if(professioni && (!professioneProfessore)){
		for(let i=0;i<professioni.length;i++){
			if(professioni[i].code==='professor'){
				setProfessioneProfessore(professioni[i]);
				break;
			}
		}
	}
    }, [professioni,professioneProfessore]);
    
    useEffect(() => {
	const cb=async function(){setInteressi((await uscall(appcontext,auth,'pim',null,'getall-interesse',null,{sort:{field:'name',desc:false}})).result);};
	if(!interessi)cb();
    }, [interessi,appcontext,auth]);
    
    useEffect(() => {
	const cb=async function(){setMaterie((await uscall(appcontext,auth,'pim',null,'getall-materia',null,{sort:{field:'name',desc:false}})).result);};
	if(!materie)cb();
    }, [materie,appcontext,auth]);
    
    useEffect(() => {
	const cb=async function(){setUniversita((await uscall(appcontext,auth,'pim',null,'getall-universita',null,{sort:{field:'name',desc:false}})).result);};
	if(!universita)cb();
    }, [universita,appcontext,auth]);
    
    useEffect(() => {
	if(curuserData && (!corsilaurea)){
		if(curuserData.universita)
			loadCorsiLaurea(curuserData.universita);
		else if(universita){
			loadCorsiLaurea(universita[0]);
		}
	}
    }, [corsilaurea,curuserData/*,loadCorsiLaurea*/,universita]);


    const handleCancel=async function(e){
		e.preventDefault();
		setChanges({});
		setCurUserData({...userData});
	}

    const handleSubmit=async function(e){
		e.preventDefault();
		if(isSubmitting)return;
		if(Object.keys(changes).length>0);else return;//nothing to save

        	//setIsSubmitted(true);
		for(let k in changes){
			if(k==='username')delete changes[k];
			else if(['firstname','lastname','email','professione','universita','corsolaurea','materia'].includes(k)){
				if(changes[k]);else{
					return;//Missing required field
				}
			}else if(['interessi'].includes(k)){
				if(changes[k] && changes[k].length>0);else{
					return;//Missing required field
				}
			}
		}
        	setIsSubmitting(true);
		try{
			let r=await uscall(appcontext,auth,'aaa','user','save',null,[{...changes,uuid:userData.uuid}]);
			if(typeof(r['error'])!='undefined'){
            			notifyWarn(
            			    process.env.PUBLIC_URL+'alert.svg',
            			    'Errore',
            			    <>{r?.error?.description}</>,
            			);
			}else{
				notifySuccess('Profilo aggiornato');
				//await loadUserData();
				setChanges({});
				reloadusercontext();
			}
		}catch(err){
	            	notifyWarn(
	            	    process.env.PUBLIC_URL+'alert.svg',
	            	    'Errore di comunicazione',
	            	    <>Ti preghiamo di riprovare più tardi</>,
	            	);
		}
	        setIsSubmitting(false);
	        //setIsSubmitted(false);
	};

    const isSaveButtonDisabled = () => {
        if (!curuserData || !curuserData.professione) return true;
    
        const isStudente = curuserData.professione === professioneStudente?.uuid;
        const isProfessore = curuserData.professione === professioneProfessore?.uuid;
    
        if (isStudente) {
            return !(curuserData.universita && curuserData.corsolaurea &&
                curuserData.universita !== "" && curuserData.corsolaurea !== "");
        }
    
        if (isProfessore) {
            return !(curuserData.universita && curuserData.materia &&
                curuserData.universita !== "" && curuserData.materia !== "");
        }

        return false;
    };
    

    return (
	<>
	{ auth.isAuthenticated ? (<>
        <div className="account-container">
            <div className="account-top">
                <div className="row h-100 align-items-center">
                    <PrevPage />
                    <div className="col-10 text-center">
                        <h1>Il mio account</h1>
                    </div>
                    <div className="col-1"></div>
                </div>
            </div>
            <div className="account-body">
                <form>
                    <h2>Utente</h2>
                    <div className='row'>
                        <div className={`col-md-6 ${curuserData?'':'placeholder-glow'}`}>
                            <label htmlFor="username" className='form-label'>Utente *</label>
                            <input
                                type='text'
                                name="username"
                                value={curuserData?curuserData.username:''}
                                readOnly
                                className={curuserData?'':'placeholder-glow'}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div  className={`col-md-6 ${curuserData?'':'placeholder-glow'}`}>
                            <label htmlFor="firstname" className='form-label'>Nome *</label>
                            <input
                                type='text'
                                name="firstname"
                                value={curuserData?curuserData.firstname:''}
                                className={curuserData?'':'placeholder-glow'}
                                onChange={(e) => {updateField('firstname',e.target.value);}}
                            />
                        </div>
                        <div className={`col-md-6 ${curuserData?'':'placeholder-glow'}`}>
                            <label htmlFor="lastname" className='form-label'>Cognome *</label>
                            <input
                                type='text'
                                name="lastname"
                                value={curuserData?curuserData.lastname:''}
                                className={curuserData?'':'placeholder-glow'}
                                onChange={(e) => {updateField('lastname',e.target.value);}}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className={`col-md-6 ${curuserData?'':'placeholder-glow'}`}>
                            <label htmlFor="email" className='form-label'>Email *</label>
                            <input
                                type="email"
                                name="email"
                                value={curuserData?curuserData.email:''}
                                className={curuserData?'':'placeholder-glow'}
                                onChange={(e) => {updateField('email',e.target.value);}}
                            />
                            <span>Utilizzeremo questa mail per contattarti</span>
                        </div>
                        <div className={`col-md-6 ${curuserData?'':'placeholder-glow'}`}>
                            <label htmlFor="mobile" className='form-label'>Cellulare</label>
                            <input
                                type="tel"
                                name="mobile"
                                value={curuserData?curuserData.mobile:''}
                                className={curuserData?'':'placeholder-glow'}
                                onChange={(e) => {updateField('mobile',e.target.value);}}
                            />
                        </div>
                    </div>
                    <h2>Profilo</h2>
		    {professioni && (
                    <div className='row'>
                        <div className={`col-md-6 ${curuserData?'':'placeholder-glow'}`}>
                            <label htmlFor="professione" className='form-label'>Professione *</label>
                            <select onChange={(e) => {updateField('professione',e.target.value);}} disabled={curuserData && curuserData.professione}>
                                {professioni.map(option => (
                                    <option value={option.uuid} selected={curuserData && curuserData.professione===option.uuid}>
                                        {option.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>)}
		<>{curuserData && professioneStudente && professioneProfessore?(<>
			{curuserData.professione===professioneStudente.uuid?(
			<>{universita && corsilaurea && 
				<>
                    <div className='row'>
                        <div className={`col-md-6 ${curuserData?'':'placeholder-glow'}`}>
                            <label htmlFor="universita" className='form-label'>Università *</label>
                            <select onChange={(e) => {updateField('universita',e.target.value);}}>
                                <option value="">Seleziona</option>
                                {universita.map(option => (
                                    <option value={option.uuid} selected={curuserData && curuserData.universita===option.uuid}>
                                        {option.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className={`col-md-6 ${curuserData?'':'placeholder-glow'}`}>
                            <label htmlFor="corsolaurea" className='form-label'>Corso di Laurea *</label>
                            <select onChange={(e) => {updateField('corsolaurea',e.target.value);}}>
                                <option value="">Seleziona</option>
                                {corsilaurea.map(option => (
                                    <option value={option.uuid} selected={curuserData && curuserData.corsolaurea===option.uuid}>
                                        {option.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
				</>}</>
			):(
			<>{curuserData.professione===professioneProfessore.uuid?(
				<>{universita && materie && <>
                    <div className='row'>
                        <div className={`col-md-6 ${curuserData?'':'placeholder-glow'}`}>
                            <label htmlFor="universita" className='form-label'>Universita *</label>
                            <select onChange={(e) => {updateField('universita',e.target.value);}}>
                                <option value="">Seleziona</option>
                                {universita.map(option => (
                                    <option value={option.uuid} selected={curuserData && curuserData.universita===option.uuid}>
                                        {option.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className={`col-md-6 ${curuserData?'':'placeholder-glow'}`}>
                            <label htmlFor="materia" className='form-label'>Materia *</label>
                            <select onChange={(e) => {updateField('materia',e.target.value);}}>
                                <option value="">Seleziona</option>
                                {materie.map(option => (
                                    <option value={option.uuid} selected={curuserData && curuserData.materia===option.uuid}>
                                        {option.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
				</>}</>
			):(
			<>{interessi && 
                    <div className='row'>
                        <label htmlFor="interessi" className='form-label'>Interessi *</label>
                        <div>
                            {interessi.map(interesse => (
                                <div className='chip' onClick={(e) => {updateField('interessi',(interessi.includes(interesse.uuid)?(curuserData.interessi?curuserData.interessi.filter((uuid)=>{return (uuid!==interesse.uuid);}):[]):(curuserData.interessi?[...curuserData.interessi,interesse.uuid]:[interesse.uuid])));}}>
                                    <span className={curuserData && curuserData.interessi && curuserData.interessi.includes(interesse.uuid) ? 'chip-icon chip-checked' : 'chip-icon'}></span>
                                    <p>{interesse.name}</p>
                                </div>
                            ))}
                        </div>
                    </div>}</>
				)}</>
			)}</>
		):(<></>)}</>
                    <div className="row buttons-container">
                        <div className="col-md-6">
                            <input className="gradient-button reset-button" type="reset" value="Annulla" onClick={handleCancel} />
                        </div>
                        <div className="col-md-6">
                            <input className="gradient-button" onClick={handleSubmit} type="submit" value="Salva" disabled={isSaveButtonDisabled()} />
                        </div>
                    </div>
                </form>
                    <ToastContainer className="custom-toast custom-toast-warning" />
            </div>
        </div>
	</>):(<></>)}
	</>
    );
}

export default Profile;
